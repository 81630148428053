<template>
  <div>
    <div style="background:#1018af">
      <el-image width="1920" height="568" :src="bannerUrl"></el-image>
    </div>
    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1320px; margin:0 auto; text-align:left">
        <ul>
          <router-link :to="{ path: '/components/web/solutions/case01' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                class="tabStyle2"
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>01</div>
                <div>{{ $t('Nav.qiyezonghehuaguanli') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/solutions/case02' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>02</div>
                <div>{{ $t('Nav.nengyuanshihuaguanli') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/solutions/case03' }">
            <li class="tabStyleLi">
              <div class="tabStyle" style="background:#3246F4"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>03</div>
                <div>{{ $t('Nav.guojiyunying') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/solutions/case04' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>04</div>
                <div>{{ $t('Nav.dashuju') }}</div>
              </div>
            </li>
          </router-link>
          <!-- <router-link :to="{ path: '/components/web/solutions/case05' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>05</div>
                <div>{{ $t('Nav.xitongjishuyuzhichi') }}</div>
              </div>
            </li>
          </router-link> -->
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">{{
            $t('Nav.home')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/components/web/solutions/index' }"
          ><span style="color:#303133">{{
            $t('Nav.cpfa')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/components/web/solutions/case03' }"
          ><span style="color:#303133">
            {{ $t('Nav.guojiyunying') }}
          </span></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">
            {{ $t('Nav.case15_1') }}
          </span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div style="height:30px"></div>
    <div
      style="width:1200px; margin:0 auto; height:2250px;background:#F6F7F8; padding-top:10px"
    >
      <div style="margin-top:20px">
        <div
          style="text-align:center; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 24px;"
        >
          {{ $t('Nav.case15_1') }}
        </div>
        <div
          style="text-indent:30px; margin-top:30px;text-align:left;line-height:28px; "
        >
          {{ $t('Nav.case15_5') }}
        </div>
        <div style="margin-top:20px;">
          <el-image
            :src="qygsImg1"
            style="width:1100px; height:562px;"
          ></el-image>
        </div>
        <div
          style="text-indent:30px; margin-top:30px;text-align:left;line-height:28px; "
        >
          {{ $t('Nav.case15_6') }}
        </div>
        <div style="margin-top:20px;">
          <el-image
            :src="qygsImg2"
            style="width:640px; height:400px;"
          ></el-image>
        </div>
        <div style="margin-top:20px;">
          <el-image
            :src="qygsImg3"
            style="width:640px; height:400px;"
          ></el-image>
        </div>
        <div style="margin-top:20px;">
          <el-image
            :src="qygsImg4"
            style="width:640px; height:400px;"
          ></el-image>
        </div>
      </div>
    </div>
    <div style="height:30px"></div>
  </div>
</template>

<script>
export default {
  name: 'solutions',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/33.jpg'),
      corporateVision1: require('../../../assets/aboutus/corporateVision-img1.png'),
      qygsImg1: require('../../../assets/chanpin/case15/1.png'),
      qygsImg2: require('../../../assets/chanpin/case15/2.png'),
      qygsImg3: require('../../../assets/chanpin/case15/3.png'),
      qygsImg4: require('../../../assets/chanpin/case15/4.png'),
    }
  },
  mounted() {
    this.$bus.$emit('getCurNav', 'solutions')
  },
  methods: {},
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 220px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.jjfaTitle {
  height: 20px;
  font-size: 21px;
  font-family: Noto Sans S Chinese;
  font-weight: 300;
  color: #2c3dd6;
  line-height: 37px;
  margin: 5px 0;
}
.jjfamiaoshu {
  height: 16px;
  font-size: 16px;
  font-family: Noto Sans S Chinese;
  font-weight: 300;
  color: #666666;
  line-height: 37px;
}
.grid-content {
  text-align: center;
  height: 250px;
  line-height: 50px;
  padding-top: 50px;
}
.grid-content:hover {
  cursor: pointer;
  background: #f6f8f9;
  height: 250px;
}
.grid-content2 {
  position: relative;
  cursor: pointer;
}
.imgText {
  font-size: 16px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #ffffff;
  margin: 10px;
}
.hzgs {
  height: 23px;
  font-size: 24px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #475aff;
  line-height: 37px;
}
.el-row {
  margin: 0px !important;
}
</style>
